var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoading),expression:"pageLoading"}],staticClass:"bg"},[_c('div',{staticClass:"top_div",on:{"click":_vm.goBack}},[_c('i',{staticClass:"el-icon-arrow-left left_link"}),_vm._v("测评报告 ")]),_c('div',{staticClass:"user_info"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.detail.createTime))]),_c('div',{staticClass:"detail"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"line_1"},[_c('div',[_vm._v("姓名："+_vm._s(_vm.patient_detail.name))]),_c('div',[_vm._v("性别："+_vm._s(_vm.patient_detail.sex))])]),_c('div',[_vm._v("年龄："+_vm._s(_vm.patient_detail.age)+"岁")])])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"main_div"},[_c('div',{staticClass:"first"},[_c('div',{staticClass:"res_tags"},[_vm._v("综合结果")]),_c('div',{staticClass:"first_result"},[_vm._v(" 您的心理状态处于： "),(
            _vm.result_detail.level_pic == '高' ||
            _vm.result_detail.level_pic == '极高'
          )?_c('span',{staticClass:"first_result_color_red"},[_vm._v(" "+_vm._s(_vm.result_detail.level)+" ")]):_c('span',{staticClass:"first_result_color"},[_vm._v(" "+_vm._s(_vm.result_detail.level)+" ")])])]),_c('div',{staticClass:"result_jieshi"},[_vm._m(1),_c('div',{staticClass:"jieshi_score_title"},[_vm._v(" 得分： "),_c('span',{staticClass:"jieshi_score"},[_vm._v(_vm._s(_vm.result_detail.score)+"分")])]),_c('div',{staticClass:"jieshi_suggest"},[_vm._v(_vm._s(_vm.result_detail.suggest))]),_c('div',{staticClass:"tips"},[_vm._v("*注明：最终结果以医护人员确认为准。")])]),_c('div',{staticClass:"second"},[_c('div',{staticClass:"res_tags"},[_vm._v("量表测评结果")]),_vm._m(2),_c('div',{staticClass:"secont_list"},_vm._l((_vm.result_detail.score_detail),function(item,key){return _c('div',{key:key,staticClass:"secont_item"},[_c('div',{staticClass:"secont_item_title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"secont_value",class:{ red: item.status == 'up' }},[_vm._v(" "+_vm._s(item.value)+" ")]),(item.status == 'up')?_c('img',{staticClass:"secont_status",attrs:{"src":require("@/assets/result_detail_up.png")}}):_c('div',{staticClass:"secont_status"})])}),0)]),_c('div',{staticClass:"third"},[_vm._m(3),_c('div',{staticClass:"card"},_vm._l((_vm.result_detail.score_standard),function(item,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',{staticClass:"fourth"},[_c('div',{staticClass:"res_tags"},[_vm._v("建议")]),_c('div',{staticClass:"card"},[_vm._v(_vm._s(_vm.result_detail.suggest))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ava"},[_c('img',{attrs:{"src":require("@/assets/ava_man.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jieshi_title"},[_c('img',{staticClass:"picture",attrs:{"src":require("@/assets/result_detail_jieshi.png")}}),_c('div',{staticClass:"jieshi_text"},[_vm._v("结果解释")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secont_title"},[_c('img',{staticClass:"picture",attrs:{"src":require("@/assets/result_detail_list.png")}}),_c('div',{staticClass:"secont_text"},[_vm._v("量表检测到您")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"third_title"},[_c('img',{staticClass:"picture",attrs:{"src":require("@/assets/result_detail_xin.png")}}),_c('div',{staticClass:"third_text"},[_vm._v("评分标准")])])
}]

export { render, staticRenderFns }